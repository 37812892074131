import React from "react";
import { graphql } from "gatsby";
// import Img from "gatsby-image";

import useSetPageData from "../../utils/useSetPageData";
import SEOs from "../../components/seo";

import ListView from "../ListView";

export default function NewsList({
  location,
  data: { pageNewsPosts, recentNewsPosts, strapiPnNews },
  pageContext: { slugToURIMap, baseURI }
}) {
  // console.log(strapiPnNews, "pn");
  const { seo: SEO, Hero } = strapiPnNews;

  useSetPageData(location);

  return (
    <>
      <SEOs
        {...{
          title: `${Hero[0].main_heading} | Insights`
          // description: SEO.description
        }}
      />
      <ListView
        title="News"
        posts={pageNewsPosts}
        recentPosts={recentNewsPosts}
        slugToURIMap={slugToURIMap}
        baseURI={baseURI}
        hero={Hero[0]}
        icon={Hero[0].insight_image.childImageSharp.fixed}
      />
    </>
  );
}

// alter me!
// filter: { published: { eq: true } }
// filter: { published: { eq: true } }
export const newsListQuery = graphql`
  query newsListQuery($skip: Int!, $limit: Int!, $recentLimit: Int!) {
    pageNewsPosts: allStrapiPostNews(
      sort: { fields: [created_at], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          slug
          heading
          content
          publish_date
          event_start_date
          event_end_date
          location
          media_image {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          created_at
        }
      }
    }
    recentNewsPosts: allStrapiPostNews(
      sort: { fields: [created_at], order: DESC }
      limit: $recentLimit
    ) {
      edges {
        node {
          heading
          id
          slug
          created_at
          location
          event_start_date
          event_end_date
          publish_date
        }
      }
    }
    strapiPnNews {
      SEO {
        description
      }
      Hero {
        main_heading
        main_subheading
        insight_image {
          childImageSharp {
            fixed(width: 100, height: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`;
