import React from "react";
import { Link } from "gatsby";
import Image from "gatsby-image";
import NewsListModule from "./_News/NewsList.module.scss";
import ReactMarkdown from "react-markdown";
import { LinkRenderer } from "../utils/utilities";

//components
// import PostItem from "./posts/PostItem";
// import LinkBar from "./posts/LinkBar";
import Hero from "../components/panels/Hero";

// styles
import s from "./ListView.module.scss";
function extractPostData({ edges }) {
  edges = edges.sort((a, b) => {
    let date1 = a.node["publish_date"] || a.node["created_at"];
    let date2 = b.node["publish_date"] || b.node["created_at"];
    return new Date(date2) - new Date(date1);
  });
  return edges.map(({ node }) => ({ ...node, slug: node.slug + "/" }));
}

export default function ListView({
  posts,
  recentPosts,
  slugToURIMap,
  baseURI,
  hero,
  icon
}) {
  const [postList, recentPostList] = [posts, recentPosts].map(extractPostData);

  const trimString = function(string, length) {
    return string.length > length
      ? string.substring(0, length) + "..."
      : string;
  };

  const getPath = () => {
    const pathFromBrowser =
      typeof window !== "undefined" ? window.location.href.split("/")[3] : "";

    switch (pathFromBrowser) {
      case "blog":
        return "blog";
      case "case-studies":
        return "case-studies";
      case "news":
        return "news";
      case "webinar":
        return "webinar";
      case "whitepapers":
        return "whitepapers";
      default:
        return "";
    }
  };
  function highlight() {
    // console.log("Hovering!!");
  }

  return (
    <>
      <Hero heading={hero.main_heading} icon={icon} />
      <div className={`container-fluid panel-padding`}>
        <div className={`container`}>
          <div className="row gx-5 p-3 mt-4 mb-4">
            {postList.map(post => (
              <div
                className="col-lg-4 col-md-6 col-sm-12"
                style={{ marginBottom: "30px" }}
              >
                <Link
                  style={{ textDecoration: "none" }}
                  to={`${getPath()}/` + post.slug}
                  className={` ${NewsListModule.cardLink}`}
                >
                  <div
                    className={`card mb-5 ${s.card}`}
                    style={{ height: "480px", border: "none" }}
                  >
                    <Image
                      className={`float-right card-img-top ${s.image}`}
                      fluid={post.media_image.childImageSharp.fluid}
                      // style={{ height: "250px" }}
                      imgStyle={{ objectFit: "fill" }}
                    />
                    <div className="card-body">
                      <h4
                        style={{ display: "inline-block", paddingTop: "10px" }}
                        className={`card-title ${NewsListModule.textUnderline}`}
                      >
                        <small className={s.cardTitle}>{post.heading}</small>
                      </h4>
                      <hr className={s.divider}></hr>

                      <p className={"card-text text-muted"}>
                        <small>
                          {/*  style={{ lineHeight: "1.3", fontWeight: "400" }}> */}
                          {/* {trimString(post.content.replace(/[!@#$%^&*]/g, ""), 250)} */}
                          {/* .replace(/[\n]/g, " ") */}
                          <ReactMarkdown
                            source={trimString(
                              post.content.replace(/[!@#$%^&*]/g, ""),
                              200
                            )}
                            escapeHtml={false}
                            renderers={{ link: LinkRenderer }}
                          />
                        </small>
                      </p>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
