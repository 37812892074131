import { useLayoutEffect, useContext } from "react";
import { layoutContext } from "../components/layouts/original/layout";

export default function useSetPageData(location) {
  const setPageData = useContext(layoutContext);

  useLayoutEffect(() => {
    setPageData(location);
  }, [setPageData, location]);
}
